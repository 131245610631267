export const quotes = [
  // {
  //   quote: '""',
  //   guest: ''
  // },
  {
    quote: '"Such a wonderful stay in this beautiful apartment.  We were visiting family in Denmead so it was great to be close by. Hope to come back and stay again soon! Happy New Year!"',
    guest: 'Craig, Rena and Erin [South Africa] January 2024'
  },
  {
    quote:
      '"We love the updates (blinds, prints, coffee table etc.) Another comfortable stay in your lovely apartment Nigel & Jenny. Recommend Christmas high tea at The Pavilion Tearoom, Stanstead Park. Happy New Year!"',
    guest: 'The McMillan family [Netherlands] December 2023'
  },
  {
    quote: '"Probably the best self catering apartment we’ve stayed in. It’s got everything you need, and more than expected. A lovely, comfortable place to just chill, but also close to shops, train and bus. We thoroughly enjoyed our stay and will recommend to others, as long as they don’t want to come the same we we choose to come back!! Thank you so much."',
    guest: 'Ivan, Daphne and Elaine [West Sussex] September 2023'
  },
  {
    quote: '"Dear Nigel & Jenny, We have had the most wonderful trip here visiting family. Staying in your holiday home has been a delight. We had everything we needed and appreciated having a lovely place to come back to every evening. Many thanks and will defintely return!"',
    guest: 'The Kennedy family [Vancouver, Canada] August 2023'
  },
  {
    quote: '"We had a wonderful holiday here in Vellum Court! We loved places like Bosham, Emsworth and Chichester. The weather was perfect, not too hot or too cold. The apartment is very well equipped, we only missed an ice cream scoop :-)  We hope to come back another year!"',
    guest: 'The Fase family [Netherlands] August 2023'
  },
  {
    quote: '"Alles in allem war es ein wunderschöner Urlaub. Die Wohnung (ehemalige Mühle) ist sehr groß und hat historisch-britischen Charme. Im Tagespendelbereich gibts jede Menge attraktive Ausflugsziele inkl. herrlichem, breiten Sandstrand (0,5 h Fahrt). Einkaufsmöglichkeiten gibts im Ort ohne Ende, diese sind sogar fußläufig erreichbar. Mit dem Zug ist man in 2 h in London! Kommunikation mit dem Vermieter absolut lobenswert!!!"',
    guest: 'The Götz family [Germany] July 2023'
  },
  {
    quote: '"We had a very enjoyable weeks stay in Nigel’s apartment which was very spacious & well equipped. He gave clear instructions of how to access the property & was very quick to respond to any queries that we had. The location for us was great & we found many interesting places to visit. Would definitely make a return visit."',
    guest: 'The Childs family [England] May 2023'
  },
  {
    quote: '"We enjoyed our stay, true home from home with great access to shops etc. Emsworth is a lovely town. People very friendly. Our grandson loved the open plan space :-)',
    guest: 'Jenny and Ray [Clacton-on-Sea] April 2023'
  },
  {
    quote: '"Thank you so much for a lovely stay. We had the best time ever. It was a week of fun packed adventures. We visited most of the attractions including West Wittering, Emsworth, Chichester, Portsmouth Historic Dockyards, Clarence pier and funfair, Bosham, Fishbourne Roman Palace, Hayling Island Donkey Sanctuar and Southsea Castle. The apartment was a perfect base with everything we could have wished for. Thank you so much!"',
    guest: 'The Gerlacks [England] April 2023'
  },
  {
    quote: '"I thoroughly enjoyed every minute of our stay at Vellum Court. It’s such a lovely place to spend time. Right in the centre of town, and minutes walk to everything, but feeling tucked away and quiet. Simply lovely."',
    guest: 'Allison and Robert [Cornwall] March 2023'
  },
  {
    quote: '"Yet another great stay here. Lovely to catch up with family nearby and the weather was fantastic. Will be back again. Thank you for having us here."',
    guest: 'John & Kirsten [England] February 2023'
  },
  {
    quote:
      '"We had a very relaxing stay here and enjoyed exploring Havant and the surroundings. We normally stay in Chichester so this was a lovely change. We would recommend - thank you for letting us stay!"',
    guest: 'Sue & Bruce [Falkland Islands] February 2023'
  },
  {
    quote:
      '"We had a very enjoyable week at Vellum Court. We were warm and comfortable despite some wet and windy weather. The kitchen was one of the best equipped that we have had in a rental home.  Thank you!"',
    guest: 'The Nelson family [Kelmscott] January 2023'
  },
  {
    quote:
      '"We have had a fabulous week here. Vellum Court has been perfect for our stay.  Many thanks to Nigel for his quick responses to our queries. We would recommend the Historic Dockyards in Portsmouth – we spent 4 brilliant days exploring there. The Old House at Home pub nearby is a must – good food and lovely staff. "',
    guest: 'The Best and Hays family[Nottingham] September 2022'
  },
  {
    quote:
      '"Dear Jenny and Nigel, Thank you for this great accommodation. We are two couples who are friends and stayed here with our motorbikes for two weeks. We have had a wonderful time. The apartment is very large, well equipped and very well located.  We were able to do many tours from here, for example swimming in the sea at Hayling Island, visit the Isle of Wight and London. The communication with you was very clear, easy and friendly. We have felt very comfortable here. Thanks so much!"',
    guest: 'Marie Therese[Germany] August 2022'
  },
  {
    quote:
      '"Staying at Vellum Court is a hidden treasure in the heart of Havant. The apartment and building close by are beautiful and historic. It\'s as if you step back in time. The apartment was clean and tidy and has a spacious living and dining area affording a relaxed and tranquil visit. We stayed in July/August and could open the enormous windows to let a lovely breeze through. The kitchen is well stocked. The apartment has all the amenities and entertainments (TV, PlayStation, games, books) you could wish for. We felt very safe because the parking and living quarters are gated. First rate."',
    guest: 'The Pernia family[USA] August 2022'
  },
  {
    quote:
      '"We really enjoyed our stay at your lovely apartment. Very good location for visiting the excellent places, such as Chichester, Portsmouth and Brighton. Plenty of food shops, pubs and restaurants within walking distance, and although close to Havant center, the Vellum Court complex is very quiet. The accommodation is large, tidy and has everything needed for a comfortable stay. Having a safe place to leave the car is also a great bonus. Thanks, will recommend to friends."',
    guest: 'The Connolly Family[UK] July 2022'
  },
  {
    quote:
      '"Another great time in this apartment. We have thoroughly enjoyed our time here."',
    guest: 'Simon & Hilliary[Derbyshire] July 2022'
  },
  {
    quote:
      '"A delightful stay. We came to attend the Goodwood Festival of Speed and found the apartment a great location which met our needs well. We also explored the surroundings and enjoyed England and its charm.  Thanks Nigel & Jenny!"',
    guest: 'Steve & Pat[USA] June 2022'
  },
  {
    quote:
      '"We had such a great time here! Such a beautiful home and perfect location. We hope to be back!"',
    guest: 'The Davis family[USA] April 2022'
  },
  {
    quote:
      '"Thank you so much for a wonderful stay. Such a great location and the loft is beautiful, quiet and clean."',
    guest: ' Faye & Simon[Canada] March 2022'
  },
  {
    quote:
      '"We had a great stay here! The flat was very clean when we arrived, and we felt very comfortable. Nigel was very communicative and made sure that we had a stress-free stay. We will recommend this place to others!"',
    guest: 'Nina & Raissa[Brazil] Feb 2022'
  },
  {
    quote:
      '"Great stay, lovely apartment. Came to work in Havant and this was lovely.  Love the open plan & space provided. We will be returning next month. Went to Portsmouth and Southsea in our spare time  and it was lovely. Thanks Nigel for the quick responses and being understanding."',
    guest: 'Sid & Morah[Leicester] January 2022'
  },
  {
    quote:
      '"Our 13th visit to the apartment – turned out to be lucky for us . We celebrated Boxing Day here with family after a couple of bleak years apart.  We recommend walking along Langstone road, then down Langstone high street to the Royal Oak, he along the coastal path. Beautiful sunset on the 26th. Red Chilli, North Street for a great Indian takeaway. Happy New Year!"',
    guest: 'The McMillan family[Netherlands] December 2021'
  },
  {
    quote:
      '"We have thoroughly enjoyed our little break in this fabulous apartment. So spacious, warm and in the perfect spot! Would definitely recommend to others! P.S. Would recommend the Wheelwright Arms for a great pub meal."',
    guest: 'The Dolan-Palmer family[Essex] October 2021'
  },
  {
    quote:
      '"Lovely warm flat, everything you need, so quiet and peaceful. So much to do and see, but so little time. Thank you for a wonderful stay. "',
    guest: 'John & Kirsten[Norfolk] October 2021'
  },
  {
    quote:
      '"Very nice apartment, we enjoyed our stay. Very spacious and lots of games for entertainment and very close to Portsmouth, lovely stay. Thank you! "',
    guest: 'The Sharma family[Leicester] October 2021'
  },
  {
    quote:
      '"Thank you Nigel and Jenny for allowing us to stay at Vellum Court. It is lovely and well equipped with everything you need, a great place to get to places, very spacious and comfortable which was very pleasing to us all. A very enjoyable holiday had by us all."',
    guest: 'Ray, Christine, Bob and Marjorie[Kent] September 2021'
  },
  {
    quote:
      '"Thanks for a great stay!  A very spacious and comfortable apartment, light and airy with interesting features and history."',
    guest: 'The Edwards[UK] September 2021 '
  },
  {
    quote:
      '"We stayed here for a few days either side of our daughter’s wedding on Hayling Island and the apartment felt like a home from home. Location was fabulous, really convenient for travelling by road or rail, and near to shops for last minute purchases. We loved the space and comfort of the apartment, slept well and would not hesitate to come back again. The sun even shone on the big day! We could not have asked for anything more. "',
    guest: 'The Gilmore family[UK] September 2021'
  },

  {
    quote:
      '"What an amazing apartment which we absolutely loved staying in. We were taken aback by how incredibly spacious it is and enjoyed being in such a historic location. The proximity of Waitrose and central Havant was so convenient. Luckily the weather was pretty good for September so we were able to visit places each day. The Weald and Downland museum, Stansted Park and Bosham were great. If you’re going to visit Bosham, look for Ice Cream Dave’s ‘Car Wash 2’ on YouTube! It’s a beautiful village and the historical connections make it especially interesting. Thanks so much Jenny and Nigel"',
    guest: 'Sharon & John[UK] September 2021'
  },
  {
    quote:
      '"It was a perfect size for a family of five, enough space for children move around, great location and very convenient to get everything, and lots to do in the neighborhood town and cities.(AirBnb review)"',
    guest: 'The Che family[UK] August 2021'
  },

  {
    quote:
      '"A very enjoyable stay in this lovely and well equipped apartment. We recommend the Wheelwrights Arms for a lovely meal and helpful staff.  Hope to visit again in the future."',
    guest: 'The Tait family[UK] August 2021'
  },

  {
    quote:
      '"Dear Jenny and Nigel, we are deeply grateful for the opportunity to live in your home, as it was ours for such a long time. We will keep in our minds and hearts a lot of lovely memories which were possible as we spent a lot of unforgettable family moments in this wonderful home."',
    guest: 'The Banna family[Italy] July 2021'
  },

  {
    quote:
      '"As usual, a great apartment. Things haven\'t changed and we enjoyed ourselves with plenty of nice visits.  See you again, many thanks Tony & Bobby"',
    guest: 'Tony & Bobbie[France] November 2019'
  },

  {
    quote:
      '"Great apartment, well equipped and great location. We will definitely recommend."',
    guest: 'The McConnells[UK] November 2019'
  },

  {
    quote:
      '"We had a great stay at your comfortable and well equipped apartment. We visited a lot of nice places around Havant and our children loved your apartment. Thanks a lot for everything and we hope to come again sometime."',
    guest: 'The Diener family[Germany] October 2019'
  },

  {
    quote:
      "\"We booked the flat so we could attend the Goodwood Revival Festival and this flat was a perfect base for us to travel to the circuit. Last time we stayed in Havant was 14 years ago to go to the Goodwood Festival of Speed, but that was in a hotel nowhere near as nice as this flat! We had a really special weekend at the Goodwood Revival in Full period outfits and we were blessed with beautiful weather! Whilst we were in the area we also went back to the Mary Rose, something we hadn't seen in 20 years - scary to think it was that long ago. If youre interest in history it's a must, as is Fishbourne Roman Palace just down the road near Chichester. We also squeezed in a couple of gardens, including West Dean Gardens which is absolutely spectacular!!! Food wise we would highly recommend the Ship Inn at Langstone Rd and the Wheelwrights Arms on Emsworth Rd. We've all had an amazing week and will have to not leave it so long to return next time :-)\"",
    guest: 'The Day family[UK] September 2019'
  },

  {
    quote:
      '"My family and I spent one lovely week here. We did a royal trip. We visited Buckingham Palace, Windsor Castle and as a big Downton Abbey fan we had to go to Highclere Castle, it\'s a must see for fans. We also saw London and it was so beautiful. In Avebury we took a picnic between the old big stones and we also enjoyed the beach on Hayling Island. I can highly recommend the restaurant/pub Wheelwrights Arms in Havant. It was a great stay and the house is marvelous. Thank you! "',
    guest: 'Tesa & Family[Bavaria] August 2019'
  },

  {
    quote:
      '"My daughter Sue and I have spent a very enjoyable week back here in Hampshire where we lived until 1975, when my family moved to live in North Yorkshire. We have travelled down to the New Forest which was great, seeing the free roaming horses and cows and visiting the local towns and villages. We were lucky to get tickets for Oklahoma at the Chichester Festival Theatre which was fantastic. This apartment with all it\'s comforts have been like a home from home, the perfect base. Thank you!"',
    guest: 'Vivienne and Suzanne[York] August 2019'
  },

  {
    quote:
      "\"The apartment was perfect! We enjoyed it's central location and really appreciated the well equipped kitchen and extras that made it home from home! Thank you! We were here for 6 nights and it wasn't enough! There is so much to do! We recommend: 1) Stanton Farm. It's only 5 minutes drive and it is a glorious country park with a small farm. 2) Chichester is a beautiful, unspoilt historical city. The cathedral is well worth a visit. 3) Hayling Island. We really enjoyed the nature reserve walk (car park on the right just as you cross the bridge). 4) Southsea is great for a meander along the coast. There's a model village, an excellent natural history museum etc all within close vicinity. Great for kids. Wish we'd brought our bikes too as excellent cycling opportunities and very flat. We hope to be back soon to continue exploring this friendly and beautiful part of the UK.\"",
    guest: 'The Fungs[Twickenham] May 2019'
  },

  {
    quote:
      '"Our first visit to this area. Very much enjoyed the apartment, it\'s location and amenities. Perfect for the four of us. Some beautiful bike routes, particularly around Chichester harbour and Marina. What a fabulous part of England! Thanks you for your hospitality."',
    guest: 'The Cleavers[Nuneaton] April 2019'
  },

  {
    quote:
      '"Firstly I would like to thank you both for allowing us to stay in your lovely apartment.  We all thought that it has all anyone could ask for and more ... a home from home! Location is great, beautiful areas to visit and friendly atmosphere walking in and around Havant. Took a trip to Brighton, Portsmouth and visited family we\'ve not see in 10 years in St Leonards - Ringwood.  We could have stayed longer!! We will certainly come back to stay again, and will recommend to family and friends. Thanks you once again for all your advice and support on booking! Look forward to our next trip down."',
    guest: 'The Hunters[Fife, Scotland] March 2019'
  },

  {
    quote:
      '"Our 10 year anniversary of staying here! We enjoyed the refurbished en-suite and, as always the spacious and comfortable apartment.  WIshing you a joyful 2019."',
    guest: 'The McMillan Family[Netherlands, UK & USA] December 2018'
  },

  {
    quote:
      '"I wish we had booked for longer.  Your apartment is lovely - large, well maintained and equipped and very comfortable.  Thank you for all the extra touches you have provided. Very much appreciated! "',
    guest: 'Gill and Chris[Canada] November 2018'
  },

  {
    quote:
      '"We spent a wonderful week in this beautiful apartment! We visited the Spinnaker Tower, London and the Isle of Wight. It was a perfect base!"',
    guest: 'The Hense family[Germany] October 2018'
  },

  {
    quote:
      '"Great place to stay with plenty of sightseeing to be had around the area. Lovely quiet place is a perfect base - would definitely return."',
    guest: 'Linda and friends[Melbourne, Australia]  October 2018'
  },

  {
    quote:
      '"We had a great time in Havant visiting a lot of beautiful places. Especially Arundel Castle, Osborne House, Hayling Island and Stonehenge was overwhelming! All the best."',
    guest: 'The Mandle family[Germany] August 2018'
  },

  {
    quote:
      '"First stay here and perfect for visiting friends and family in Southsea and the Isle of Wight. We enjoyed a visit to West Wittering and Chichester, new for us and the kids loved Queen Elizabeth Country Park - free entry and loads to do. Southsea, Portsmouth, Gunwharf Keys - always on our list and as usual a great time here. Great apartment and idea for us. Thank you!"',
    guest: 'The Thatcher family August 2018'
  },

  {
    quote:
      '"This is our first family holiday in England and we have been lucky to experience this beautiful area in a perfect location. Our boys played Monopoly a lot and enjoyed the nice equipment which is provided in the flat, after the day trips.  We can take a lot of good memories to the next place of our trip. Best wishes from Germany."',
    guest: 'The Gerber family[Germany] July 2018'
  },

  {
    quote:
      '"Wonderful apartment with all the home comforts!  Our sons were very happy to discover a Playstation! Perfect base to discover the New Forest, Portsmouth Historic Dockyard and Beaulieu Motor Museum.  Would definitely recommenced to anyone."',
    guest: 'Andy and Jen May 2018'
  },

  {
    quote:
      '"Back again with just Lawrence this time! Lovely as ever and great to be able to let him dash around (18 month whirlwind)  Looking forward to our next visit. Many thanks!"',
    guest: 'Steve and Maresa April 2018'
  },

  {
    quote:
      '"We have had a great week here in Portsmouth.  This apartment is definitely a home away from home. It is comfortable clean and well equipped.  We would definitely stay here again should we return to the area."',
    guest: 'The Jennings family April 2018'
  },

  {
    quote:
      '"It was a great pleasure again to spend one week in this lovely apartment in this beautiful little town.  We enjoyed every hour that we\'ve been here."',
    guest: 'The Peplau family[Berlin] March 2018'
  },

  {
    quote:
      '"Once again Nigel, thank you for a most enjoyable stay in your lovely apartment. We have all thoroughly enjoyed our brief stay. As my son has just pointed out, you know when you have been here a few times when your IT devices automatically connect to the Wifi! Thank you."',
    guest: 'The Spong family[Sherington] November 2017'
  },

  {
    quote:
      '"We have really enjoyed staying in your lovely apartment and liked playing your games and listening to music. It is a lovely clean and warm apartment and very central to all destinations.  We really enjoyed Gunwharf Quays and the Spinnaker Tower and went back to go up again at night.  A lovely relaxed week off. Thank you!"',
    guest: 'The Pullin family[Chippenham] October 2017'
  },

  {
    quote:
      '"This is a lovely apartment with everything needed for a holiday. There is plenty to do locally and we didn’t have to travel more than 20 mins. We all liked this. We had a super holiday.  Thanks."',
    guest: 'The Elson family[Leicestershire] October 2017'
  },

  {
    quote:
      '"We leave this cosy and wondering flat after a 2 week stay. We felt like we were at home. There is so much to see around Havant.  We enjoyed our visits to Stonehenge, Portsmouth Historic Dockyard, Arundel Castle, the New forest and Chichester.  Had a good times at the beach and in the pubs too!   Many thanks!  "',
    guest: 'The Schuhmacher family[Germany] September 2017'
  },

  {
    quote:
      '"Had a very enjoyable stay here. A real home from home with everything we needed and more to enjoy our stay. So convenient for supermarkets, park and ride into Portsmouth and the wider area. Great pubs within walking distance too.  The Cromak family"',
    guest: 'The Cromack family[Norfolk] August 2017'
  },

  {
    quote:
      '"Very central to make visits to Portsmouth, Isle of Wight, Petworth House, Arundel, Bognor and the Witterings. Great apartment.  Thank you!"',
    guest: 'The Schmidt family[Germany] July 2017'
  },

  {
    quote:
      '"Very enjoyable stay in Vellum Court. Enjoyed visiting Portsmouth Naval Museum, Spinnaker Tower and shopping centre.  West Wittering beach is wonderful and we even did a park run in Staunton Country Park.  Apartment was great."',
    guest: 'The Keane family[Ireland] July 2017'
  },

  {
    quote:
      '"We have thoroughly enjoyed our stay here. It has been lovely. Very homely and the flat is beautiful. Our nine month twins enjoyed it especially. We will be back in the very near future!"',
    guest: 'The Bowen family June 2017'
  },

  {
    quote:
      '"Our first visit in Hampshire area has been amazing. Very warn, almost hot days (about 30 °C), beautiful flowers and landscapes and nice visits to different places. Thanks for a pleasant location and all.  Fantastic apartment! Lots of room to keep our lively 3 year old pleased and Grandpa too since he was the favourite playmate. It really felt like a home from home so it made it very relaxing to explore countless activities in the Hampshire and West Sussex area.  We’ll be back!"',
    guest: 'The Aali family[Finland] June date 2017'
  },

  {
    quote:
      '"This was our third break in Vellum Court and was thoroughly enjoyable. This time we had our family and the space and facilities were well appreciated. Thanks for such a pleasant location and ambience.  We’ll be back."',
    guest: 'Tony and Bobbie[France] May date 2017'
  },

  {
    quote:
      '"Our sixth weekend stay here for the ASA Regional Swimming Championships.  Always lovely, clean and quiet and makes a very pleasant and easy base for the area.  Hope to visit again in April."',
    guest: 'The Spong family[Sherington] November 2016'
  },

  {
    quote:
      '"Our third stay here and as enjoyable as ever.  We visited the Dockyard again - always new things to see.  The D-Day museum and tapestry at Southsea are worth a visit.  We did the Millennium Trail around the old streets of Portsmouth and Nelson\'s Trail (both available to download on line) are very interesting.  The latter takes in the cathedral where there are guides to give (free) tours.  Emsworth is a very pretty small town and Chichester with it\'s beautiful cathedral and Palace Gardens are always worth a visit."',
    guest: 'Sue and Keith[Suffolk] October 2016'
  },

  {
    quote:
      '"Thank you so much for allowing us to rent your lovely apartment for our holiday.  It is one of the best equipped places we have every stayed in and it really was a home from home and a prefect location to explore the area.  Our 4 year old loved the toys and games, we didn\'t even show her the PS2 as she was quite happy playing games from our childhood such as Buckeroo and Jenga!  We found lots of things to do during our stay. Highlights include Peppa Pig World and the Cass Sculpture Park. We all loved the Victorious Festival in Portsmouth and would definitely recommend an evening trip to Port Solent as it has a great choice of restaurants in a lovely setting. We will recommend the apartment to our friends and may even return ourselves one day. "',
    guest: 'The Hebblethwaite family[Preston] August 2016'
  },

  {
    quote:
      '"Thank you so much for the opportunity to stay at this wonderful cottage! We have had the most marvellous time!"',
    guest: 'The Weske family[Berlin, Germany] August 2016'
  },

  {
    quote:
      '"We\'ve spent a marvellous week in Havant - thanks to an excellently furnished and equipped apartment where obviously a lot of thought has been spent on details.  For kids, we too can recommend the West Wittering sandy beach and the Historic Docks in Portsmouth.  As for food, we couldn\'t help but use the perfect kitchen instead of going out most of the time.  London is close enough - even though the trains seem to have a  tendency to be delayed. A day out to Stonehenge (1.5 hours drive) turned out just perfect - one of us even got a slight sun burn. We will recommend the apartment and will most certainly return. "',
    guest: 'Thomas, Pia, Jan, Julia & Helene[Bonn, Germany] July 2016'
  },

  {
    quote:
      '"Lovely apartment and a fantastic holiday.  Visited Paultons Park, West Wittering Beach, Arundel Castle and crabbing in Emsworth.  Five minutes down the road is Staunton Country Park which is great for the kids.  Apartment was in a great location for all of our days out, thoroughly enjoyed our stay at Vellum Court and would definitely recommend!"',
    guest: 'The Roan family[Essex, UK] July 2016'
  },

  {
    quote:
      "\"We love your beautiful apartment, it had everything we needed and what a fabulous space and building!  We didn't visit Portsmouth Historical Harbour as we've been before 9 (but it is brilliant!).  We did discover West Wittering sandy beach, which is the best beach we have found in this area. Perfect for the kids.  The parking is expensive, but the beach is worth it! We had some lovely food out too - we recommend The Ship Inn (just towards Hayling Island), the Wheelwright Arms and the Old House at Home in Havant and the Lobster Pot in Bognor. Hayling Island itself was a bit tacky and a bit of a disappointment.  Arundel Wetland Wildlife Centre was a great for a day out too! ",
    guest: 'Melanie, Sam, George, Ella and Patricia[UK] June 2016'
  },

  {
    quote:
      '"Excellent week in a supremely comfortable apartment.  We enjoyed the space and the facilities.  Our French friends were very impressed and we will try to return soon. Thanks and bientot."',
    guest: 'Bobbie and Tony[France] May 2016'
  },

  {
    quote:
      '"We feel quite at home now when we visit, it is so relaxing to know the we are coming to such a lovely apartment.  Hope to visit again in November"',
    guest: 'The Spong family[Sherington] April 2016'
  },

  {
    quote:
      '"Lovely apartment - very comfortable. The main purpose of our visit was to re-viist the Historic Dockyard, having purchased the all attraction ticket giving us year long access.  Had fantastic two days in main dockyard plus drove over to Gosport for submarine and explosion museums.  We were also surprised a the good price for Isle of Wight car Ferry - pay for car and up to 7 passenger included. Had lovely day at Osbourne House and wish we\'d spent time in Ryde too.  Also recommend Scoops Ice Cream shop in Southsea for a special treat.  Lovely comfortable and restful holiday with time for a few sights too."',
    guest: 'The Davison Family[Durham] April 2016'
  },

  {
    quote:
      "\"We have come down to celebrate my uncle's 100th birthday on Friday 10th March.  We could not have chosen a nicer place to stay.  The apartment is everything the website said and more.  Very quiet and peaceful.  If you want to eat out, the Wheelwright's is very good (about a 10 minute walk) or if eating in, Curry Cottage is also very good and deliver FOC.  It is also an ideal base for doing all the things other guests have written about.  Two things we haven't seen mentioned by are well worth visiting are: Weald and Downland Open Air Museum for it's reconstructions of historic houses and agricultural buildings dating from the 13th centrury and Bustar Ancient Farm which displays house dwellings from 4000 B.C.\"",
    guest: 'The Pooley family[Colchester] March 2016'
  },

  {
    quote:
      '"Another stay at this excellent apartment. So convenient for Portsmouth and the Historic Dockyard.  We spent another two days at the Dockyard, so much to see and do - taking the train from Havant.  We also visited the Royal Armories collection at Fort Nelson.  The guided tour is excellent (see website for times, on £3 for this) and well worth doing before exploring on your own.  Otherwise it\'s free entry and I am sure children from about eight upwards would love it there!  Romsey town and the Abbey are worth a visit and of course, Gun Wharf Quay designer village for retail therapy!  The Wheelrights Pub is as good as ever but you do need to book."',
    guest: 'Sue, Keith and Nick [Suffolk] Feb 2016'
  },

  {
    quote:
      '"Brilliant stay, lovely warm and cosy. The Maypole on Hayling Island does a superb Sunday lunch."',
    guest: 'Claire[Canada] Feb 2016'
  },

  {
    quote:
      '"Perfect place to make basecamp.  Warm, comfortable, luxury and convenient.  Highlight of our trip was attending Chichester Theatre to watch the Moscow State Ballet perform Swan Lake.  Thanks again."',
    guest: 'The Mawdsley family[Hampshire] Jan 2016'
  },

  {
    quote:
      '"Thanks for a wonderful place to stay. Quiet and comfortable, it was a pleasure to stay here.  If I ever have to come back to this area, I would hope to stay here again."',
    guest: 'Lenehan and Fields families[Canada] November 2015'
  },

  {
    quote:
      '"Thank you again for another short but wonderful stay in your lovely apartment. We are hoping to come again in 2016."',
    guest: 'The Spong family[Sherington] November 2015'
  },

  {
    quote:
      '"A wonderful spacious apartment which was very comfortable and ideal for our stay. Fantastic location to enable us to explore Hampshire and West Sussex.  Delightful meal at the Royal Oak, Langstone.  Amazing views from the Inn on the Beach, Hayling Island.  Definitely worth a visit for a drink and bite to eat. Many thanks."',
    guest: 'The Ashforth family[Staffordshire] October 2015'
  },

  {
    quote:
      '"A perfect location for our family while we were waiting to move into our new house.  Great railway access and fantastically comfortable accommodation.  Thank you!"',
    guest: 'Helen & Rob[Hampshire] October 2015'
  },

  {
    quote:
      '"A wonderful weeks holiday in a fabulous apartment. Ideal location for visiting Portsmouth and many other attractions in the area. The Historic Dock Yard was amazing, so much to see and do, which was made more special as we had my mum and dad(ex submariner) who started their married life in Portsmouth to give us the grand tour! We would definitely like to come back again soon as there is lots more for us to see."',
    guest: 'Ann, Steve, Margaret and Roy[Debyshire] June 2015'
  },

  {
    quote:
      '"This is a lovely apartment. We have been here just a week whilst our local house is under renovation, very open, airy and central to Havant. Thank you!"',
    guest: 'Kevin, Sheila and Gareth[Emsworth] June 2015'
  },

  {
    quote:
      '"Great central location and a lovely open airy space to stay in.  Sure we\'ll be back ... our friends loved it too.  Best local breakfast was a the Lavender tearooms, closely followed by the station cafe. Trips to the Isle of Wight and Gunwharf keys, Chichester and Brighton all so easy to get to.  Thanks! "',
    guest: 'The Taylors[Fife, Scotland] April 2015'
  },

  {
    quote:
      '"The apartment lived up to expectations from your advert.  It\'s been the ideal location for us to relax, whilst house hunting in the area.  We trust we left it as we found it and will certainly recommend it to others or use it again ourselves if the need arises.  God bless and keep you. "',
    guest: 'The Shaws[Merseyside] February 2015'
  },

  {
    quote:
      '"A deceptively large space, great for what we needed.  We\'ve enjoyed our stay and would definitely consider staying here again if we needed something near Portsmouth."',
    guest: 'The Fields family[Portsmouth] January 2015'
  },

  {
    quote:
      '"A fantastic apartment, ideally located.  Thank you so much for allowing us to stay this weekend, it gave our daughter an opportunity to have a good rest in between her swimming commitments.  We have all decided we want to visit again in the summer. Hopefully then we will have more opportunity to explore."',
    guest: 'The Spong family[Sherington] December 2014'
  },

  {
    quote:
      '"All the recommendations of places to visit well worth a read.  Portsmouth train to Historic Dockyard from Havant very good value.  Spinnaker Tower is brilliant.  Also visited Stanton for a delightful Autumn walk and the walk from Havant to Hayling also a lovely walk. The Lavender Cafe in Havant does excellent cakes and cream teas with friendly staff.  Have a lovely time in this delightful area."',
    guest: 'The Naldrett family[Exeter] November 2014'
  },

  {
    quote:
      '"A nice apartment ideally situated for visiting Portsmouth and Chichester.  We used the train to visit the Historic Dockyard.  Highlights are the Mary Rose exhibition, HMS Victory and the Harbour tour.  We also visited Beaulieu Motor Museum, Palace and Abbey.  Aroma Chinese restaurant in Havant is excellent value and good food. (Takeaway was also available).  Also the Wheelwrights Arms fantastic food and service.  You need to book at both. Both an easy walk from here. Canal boat ride at Chichester was good value for a 75 minute boat trip.  "',
    guest: 'Susan, Keith and Nick[Suffolk] October 2014'
  },

  {
    quote:
      '"This week wasn\'t a holiday but coming back to your wonderful apartment was! With just the two of us we had so much space and it felt very luxurious. It has been a great place to relax and for having slow dinners for the two of us.  We would love to come back.  Thank you for all your help and your kind gesture for Thursday."',
    guest: 'Tony and Bobbie[Chambilly, France] September 2014'
  },

  {
    quote:
      '" Our first time visiting S.E. England. Very warm and friendly area.  :-) We enjoyed day trips to Hayling Island, Emsworth, Portsmouth and even Newbury to visit Highclere Castle.  Hope to return one day!"',
    guest: 'Lynn, John, Brenda and Doug[Michigan,USA] September 2014'
  },

  {
    quote:
      "\"What can we say that hasn't already been said! We've had a lovely week and have been very comfortable.  The flat is wonderfully located for so many things - we've especially enjoyed the many attractions in Portsmouth and would recommend the Submarine Museum and the views from th Spinnaker Tower.  On a very wet Bank Holiday the PlayStation was a hit with our young duo - even if they are aged 24 and 27! Thank you for all the thought that's gone into this super holiday venue.\"",
    guest: 'The Thackray family[Yorkshire] August 2014'
  },

  {
    quote:
      '"Well, what can we say!  Just a bug thank you for the great apartment with so many extras.  A real home from home.  Peppa Pig World at Paultons Park was a must for Thomas aged 2 and his older sister Lucy enjoyed it too!  A great base for visiting family at Midhurst. We also used Park and Ride which proved a great service.  The week has passed so quickly.  Didn\'t get time to watch TV and play the games but there were there, just in case!  We were lucky with the weather too."',
    guest: 'The Starr family[East Sussex] August 2014'
  },

  {
    quote:
      '"Dear Jenny and Nigel, We spent a great week in your spacious flat which is so well equipped for a real home from home holiday. It is centrally located not only for the local shops and pubs, but also for lots of tourist attractions.  The Historic Dockyards in Portsmouth are a must for every visitor.  We are planning to come back for the the Mary Rose museum - what a gorgeous museum.  Thank you."',
    guest: 'The Schauerte Family[Germany] August 2014'
  },

  {
    quote:
      '"Thank you for this lovely apartment. Everything we could ask for is in it!  We visited Arundel Castle, Portsmouth and Hayling Island for a good day on the beach.  By train we went to London in about 1 hour and 15 minutes we were at Waterloo station.  The shops and pubs are nearby and Vellum Court is a home from home! Thank you!"',
    guest: 'Colinda, Teo, Ian and Danny[The Netherlands] August 2014'
  },

  {
    quote:
      '"Dear Jenny and Nigel, thank you very much for this lovely apartment. We have spent an excellent two weeks here.  We have been to Stonehenge, Arundel Castle, New Forest, Isle of Wight by Hovercraft, the Three Cathedrals and, and ...  Gunwharf Quarys and Southampton were good for shopping places, our children enjoyed the beaches and your PS2 :-)  Thanks again!"',
    guest: 'The Krusat family[Germany] July 2014'
  },

  {
    quote:
      '"Your apartment is beautifully presented and suited our needs perfectly. We were impressed with the quiet location, and enjoyed the privacy/security of Springwell Court with the convenience of being close to Havant centre, The Ship and the Royal Oak at Langstone. The size of the living room and kitchen was impressive and the decor appealing.  We also have a holiday house in Australia which we lease so we appreciated all the amenities which you provided.  Congratulations on your comprehensive Information book. I hope you will accept a booking from us in the future as we would certainly like to return."',
    guest: 'David and Ann[Australia] July 2014'
  },

  {
    quote:
      '"Just a note to say thank you very much for our holiday at your luxury apartment. The facilities provided were excellent and it is so conveniently situated for all the places we like to visit in the area such as the Historic Dockyard, Chichester and Goodwood, to name but a few. We thoroughly enjoyed our holiday, the weather was absolutely lovely, and we now have many happy memories of our recent stay.Once again, many thanks"',
    guest: 'Brian and Maureen[Birmingham] June 2014'
  },

  {
    quote:
      '"What an amazing holiday in such beautiful accomodations - many thanks!  In Hampshire for family celebrations and Vellum Court proved to be the perfect base for all our excursions and the centre of where our happy memories began!  Such lovely touches to make this a home from home, including wifi and lego for our 5 year old daughter - many thanks!  Can heartily recommend Fishbourne Roman Palace, Arundel Castle and for a lovely dining experience, the Watermark at Port Solent.  Thanks again to Jenny and Nigel for a fab stay - will definitely be returning!!"',
    guest: 'The Emery family[York and Durham] May 2014'
  },

  {
    quote:
      '"Thank you for having us in your lovely apartment. It\'s quite big enough for our family with three children.  Our two daughters, 14 and 12 years old, enjoyed to go shopping in Portsmouth and Brighton.  Our son, 10 years old and very interested in history, had wonderful days at Arundel Castle and the HMS Victory.  And we, as parents, were very happy to return in the evening to an apartment that feels like home. Thanks you!"',
    guest: 'Family Slabbers[The Netherlands] May 2014'
  },

  {
    quote:
      '"Dear Nigel and Jenny, We had a great time at Vellum Court. The apartment is so nice and cmfortable and very wel located. I was pleased to stay in Havant where I worked in 1981, many things have changed but hte old Town is still nice. Thanks! "',
    guest: 'The Morou family[France] April 2014'
  },

  {
    quote:
      '"We have really enjoyed our week in this beautiful apartment - it has gone so quickly! It has been great to explore new places and even get on a beach.  A highlight of the holiday for us was the Historic Dockyard and especially the Mary Rose. This was certainly a lucky find on the Internet - you have a beautiful place here,  we loved it."',
    guest: 'The Baird Family[Nottingham] April 2014'
  },

  {
    quote:
      '"Just wanted to say how much we enjoyed our week in your lovely apartment - excellent accommodation and such an ideal location. Really like the small touches like the Lego - kept our 4 year old happy every morning before we went out. Had some great days out at the Spinnaker Tower, Historic Dockyard, Hayling Island, Stansted House and Marwell Zoo. Couldn\'t have hoped for better weather either! Sun every day. Will definitely recommend this property to friends and family. Already thinking about coming back in the future. Thanks again."',
    guest: 'The Arrowsmith Family[Kent] March 2014'
  },

  {
    quote:
      '"Dear Nigel and Jenny, we have had a great time and we will definitely be coming again this summer. Havant is such a wonderful place with many shops to browse. The apartment itself cannot be faulted with everything you need being here. Absolutely gorgeous. Thank you for letting us stay here and we have had a great time."',
    guest: 'Chris, Louise and Jacob[Chester] February 2014'
  },

  {
    quote:
      '"Our 4th Christmas at Vellum Court - what a great present to discover you have WiFi now! The apartment has been maintained to a high standard and some items have been replaced. Thoroughly recommend a visit to Stanstead Park - lovely walks and a super lunch/afternoon tea menu in the charming Orangery. Our best wishes for a happy 2014."',
    guest: 'The McMillan Family[Netherlands, London & Oxford] December 2013'
  },

  {
    quote:
      '"Dear Nigel & Jenny,  Many thanks for providing such a comfortable accomodation.  The location was ideal in facilitating our travel plans for this area.  We look forward to a future stay!"',
    guest: 'John, Millie and Roger[Ontario, Canada] November 2013'
  },

  {
    quote:
      '"This apartment was amazing. I loved the games and especially Cluedo and Downfall. Peppa Pig World was awesome, especially Windy Castle.  The actual Theme Park itself was awesome - I loved it.  At the beach in Hayling Island I found a commemorative coin and a shell which looked like an ice cream.  We went on the Witches and Wizards express train and nearly everyone was dressed up as Harry Potter"',
    guest: 'Sasha and family[London] October 2013'
  },

  {
    quote:
      '"Dear Nigel & Jenny, We have had a great time!  The apartment is very comfortable. Havant is a nice town. Lulworth cove is a very nice day trip.  The national motor museum in Beauleau is wonderful too. Thank you for letting us stay here and for the wonderful time."',
    guest: 'The Peplau family[Germany] September 2013'
  },

  {
    quote:
      '"Dear Jenny & Nigel, Thank you for letting us stay in your lovely apartment.  Very clean, tidy and comfortable like a home away from home with everything we needed.  We will definitely come to stay here again next time we visit from Australia."',
    guest: 'Wendy & Katrina[Australia] September 2013'
  },

  {
    quote:
      '"Dear Nigel & Jenny, We had a great time in your wonderful large apartment.  The many attractions in the immediate vicinity are fantastic.  Many thanks for everything!"',
    guest: 'Thomas & family[Cologne, Germany] August 2013'
  },

  {
    quote:
      '"Hello hello.  We\'ve had such a great stay!  Such a tip top apartment - full of character.  Spacious, light, airy and so well equipped!  Our out and about highlights include: West Wittering beach - a fantastic beach - you have to pay to park but well worth it. Peppa Pig World - great day for toddler age whiles there\'s loads for older kids at Poultons Park which is part of the same complex. Fat Olive - this small restaurant in Emsworth serves delicious food in a warm and welcoming way.  We loved out meal there! Thank you very much..."',
    guest: 'Rob, Beccy and family[Derby] July 2013'
  },

  {
    quote:
      '"Family Schüßler from Germany say \'thank you\' for living in this big and well equipped apartment.  The apartment is very central to the shops, the bus station, the train station and so on.  Our two daughters will miss the playstation very much after our holidays.  Also thank you for your kindness in the mails before our journey.  Perhaps we will come back next year?!  Many thanks!"',
    guest: 'Schüßler family[Germany] July 2013'
  },

  {
    quote:
      '"Really enjoyed our stay here.  The apartment was ideal with all that we needed.  Enjoyed the Havant location and we re-visited many places from distant past!  Plus lots to see and do in the area.  Really appreciated being so close to the centre, trains, buses etc.  Plus lots of good walks. Thank you."',
    guest: 'The Crisp family[Oxon] June 2013'
  },

  {
    quote:
      '"Had a nice visit with many friends and family while staying here.  The location is just right for everything you need.  I would recommend the bangers and mash from the Royal Oak if a meal is needed."',
    guest: 'Peter[Canada] May 2013'
  },

  {
    quote:
      '"Our stay here was fantastic!  Havant is a lovely place and your apartment is smack in the middle of it all.  Thank you very much for the use of this wonderful apartment"',
    guest: 'The Atkins family [Kansas City USA] February 2013'
  },

  {
    quote:
      '"We had a lovely stay, the apartment was fully equipped and there was nothing that wasn\'t thought about. We were very comfortable here and we look forward to spending time here again.  Very nice location close to everything!"',
    guest: 'The Oakes family [California] Christmas 2012'
  },

  {
    quote:
      '"We had a wonderful 5 days in the apartment.  The Christmas tree added just the right touch for our pre-Christmas family celebration on Sunday. The weather was wonderful  - bright and cold, so walks round Hayling Island and Thorney Island were excellent.  Highly recommended. "',
    guest: 'The Scott family [Letchworth] December 2012'
  },

  {
    quote:
      '"Dear Nigel & Jenny, Thank you so much for letting us stay in this truly wonderful \'homely\' apartment"',
    guest: 'Jamie [Yorkshire] November 2012'
  },

  {
    quote:
      '"This is our second stay at this apartment and I wouldn\'t rule out another stay in the future.  We find the location perfect for trips to Portsmouth, Chichester and the kids love popping into town for a few bits and bobs."',
    guest: 'The Benham family [St Albans] October 2012'
  },

  {
    quote:
      '"We are sad to leave your wonderful apartment.  All the great reviews we read were reflected in reality. Simply a lovely place to call your home away from home.  Thanks so much for all the special touches! My Dad and I had a lovely time here.  "',
    guest: 'Jane and Syd [Canada] September 2012'
  },

  {
    quote:
      '"Thank you for making our stay so enjoyable. The apartment is very well equipped which made it feel like home.  The open plan layout worked very well for us as we have my elderly mother with us.  This is a lovely location, very quiet and peaceful.  We particularly enjoyed the fantastic views from the Spinnaker Tower and the suggested Millennium walk from the Tourist Office.  Portchester Castle and the Wildfowl and Wetlands Trust at Arundel were well worth a visit.  We also enjoyed the quick hovercraft trip to the Isle of Wight.  We would certainly recommend the apartment to others. "',
    guest: 'Ray, Wendy and Audrey [Bristol] September 2012'
  },

  {
    quote:
      '"Thank you, we had a lovely stay.  The apartment is very nice and Havant is a nice town to start a lot of activities in the South of England.  We stayed only for one week and it wasn\'t enough to see all the interesting things.  We were for our second time in London and the city gets bigger and bigger.  Portsmouth was very nice, especially the views from the promenade.  On Hayling Island we missed the wind because the reason for our visit was to go kiting, maybe next time? So our long journey goes on, we also will stay a couple of weeks in France. All the best from Berlin"',
    guest: 'The Henninger family[Germany] September 2012'
  },

  {
    quote:
      '"First of all we like to say many thanks for a marvellous two weeks in your comfortable and lovely apartment!  Everything we needed was available here and anything else we could easily get in and around Havant.  The Royal Oak was a real pub recommendation for lunch and the walk back along the seaside, then the footpath through the forest to Havant  is really great and we do recommend this.  Another highlight has been Arundel Castle, Chichester Cathedral and you must have a drink in the pub opposite the Cathedral, which has been a church in the past. An absolute recommendation is to book the walking tour of Brighton, where you will be shown around by really nice local guides and of course to places you would never find yourself!  Portsmouth, Southampton and three days in London have added a great value to our first England holiday, but too little time so we definitely need a follow up here ! Again, thank you and a warm greetings from the Harfmann family."',
    guest: 'The Harfmann family [Austria] August 2012'
  },

  {
    quote:
      '"Having read the visitors book I would have to say I agree with everything other people have said.  We came for a very lazy time and apart from venturing to Brighton and Chichester, being able to walk to the shops, supermarket, bank etc it was fabulous.  We enjoyed our walks to the Royal Oak and the Ship as well!   However the bonus for us was the comfy sofas in your lovely apartment to watch the Olympics which meant we often went out later in the day than we intended!  Many thanks! "',
    guest: 'Sheila and Eric [Derbyshire] August 2012'
  },

  {
    quote:
      '"Thank you for two fantastic weeks.  Our daughters loved the play station !!!  Havant is a nice small town.  It was the right decision to come here.  We were two times in London by Train.  That was a great experience.  Bye bye till soon!" ',
    guest: 'The Niederstein family [Germany] July 2012 '
  },

  {
    quote:
      '"We can agree with other guests.  A good base for trips, clean and convenient!  We went to London with the train, to Oxfordshire and studied the Murders in Midsomer by car.  Went to Chichester one day and looked at the Cathedral.  In the evenings we visited the local pubs.  The children (and dad) liked the movies, TV Channels and the TV Games. We only missed the garlic press and a Swedish cheese cutter." ',
    guest: 'The Larsson family [Sweden] July 2012 '
  },

  {
    quote:
      '"Lovely apartment, everything you could want. Looking forward to staying again next year. " ',
    guest: 'Brian and Eileen [Essex] July 2012 '
  },

  {
    quote:
      '"We have had a most enjoyable week here in this lovely apartment.  It has been a great base for visiting family and sight seeing.  So a big thanks you from the three of us." ',
    guest: 'The Munday family [Norfolk] June 2012 '
  },

  {
    quote:
      '"An extremely well equipped apartment in a great location.  Many thanks." ',
    guest: 'Carole and John [Cheshire] June 2012 '
  },

  {
    quote:
      '"Thoroughly enjoyed our week here with such a variety of things to do and see in the locality.  Chichester is well worth a visit and for retail therapists the outlet shops around the sail in Portsmouth are a must! " ',
    guest: 'Rob and Max [Edinburgh] June 2012 '
  },

  {
    quote:
      '"What a lovely flat! Everything was perfect and we saw so much of the vicinity.  The Historic Waterfront with the Dockyards were really impressive and we had a great "curry night" on Thursday at the Wetherspoon.  The apartment is just gorgeous with everything you need.  Thanks a lot!  " ',
    guest: 'The Will family [Germany] April 2012 '
  },

  {
    quote:
      '"This has been our third visit to Vellum Court.  It now feels like our second home. The addition of internet and coffee maker improved our stay a lot.  Easy to keep in touch with family at home in Canada.  The weather this year has been wonderful, we are fit and tanned from our many walks." ',
    guest: 'Carol and Eric [Canada] March 2012 '
  },

  {
    quote:
      '"We have enjoyed a great week in your lovely apartment.  Very comfortable and well equipped,  lots of extras.  Would not hesitate to book here again and recommend to family and friends.  The whole development is very attractive and the close proximity to the town is great.  Thanks again. " ',
    guest: "The Williamson's [Nottingham and Thailand] November 2011 "
  },

  {
    quote:
      '"It was a wonderful week here at Vellum Court.  We enjoyed every hour in this lovely, wonderful apartment, also out trips to Portsmouth, Chichester, the New Forest and the horce racing at Salisbury.  We hope that we will come back another time.  Thanks you very much and kind regards." ',
    guest: 'Peplau family [Berlin] October 2011 '
  },

  {
    quote:
      '"We enjoyed our stay in your beautiful apartment. It\'s a fabulous place to stay and to explore the South of England.  We were lucky with the weather and so we were able to visit a lot of interesting places.  We will definitely recommend your apartment and hope to come back one day !   Thanks you very much!" ',
    guest: 'Redeker family [Germany] October 2011 '
  },

  {
    quote:
      '"We had a lovely stay - loved the apartment and it\'s location.  Can highly recommend Spinnaker Tower and the Historic Dockyard / Gunwharf Quay at Portsmouth, Marwell  Zoo and of course Peppa  Pig world at Paulton\'s Theme Park (We went twice!),  Thanks again, " ',
    guest: 'The Bartlams family [Nottingham] September 2011 '
  },

  {
    quote:
      '"Dear Nigel and Jenny, thank you for a lovely summer break.  We now know a little more of the Chichester Harbour and coastline and are becoming slightly more confident in our little boat.  3 teenagers all happy (No mean feat!) and Mum and Dad suitably relaxed.  Thank you for all the helpful information beforehand and such a beautiful apartment.  " ',
    guest: 'The McKeown family [Gloucestershire] August 2011 '
  },

  {
    quote:
      '"We had a wonderful time here at Vellum Court.  The apartment is very lovely and comfortable.  We enjoyed the good weather, our trips to Portsmouth, Chichester, Winchester, Amberly, the beach at West Wittering, an exciting day in London, the meals in the Royal Oak ...  We will recommend this flat to our friends and relatives. " ',
    guest: 'The Mengers  family [Germany] August 2011 '
  },

  {
    quote:
      '"Hello from the Trumans.  We lived at Vellum Court for 3 weeks, it felt like home for all of us.  We enjoyed Havant and would come and stay here again. It was wonderful to visit with friends and family and we made the right decision renting our own place.  Thank you for putting together such a grand place to stay." ',
    guest: 'The Truman family [Canada] July 2011 '
  },

  {
    quote:
      '"We had two weeks to visit all the lovely places surrounding.  We did it by car, bus and train.  Busses and trains are very cheap so that the driver could it enjoy it too.  Portsmouth, Chichester, Brighton, London, the Isle of Wight.  Too much to mention it all.  Your apartment is wonderful and well equipped and very comfy.   We will recommend it to family and friends." ',
    guest: 'The Claessen family [Netherlands] June 2011 '
  },

  {
    quote:
      '"Good location, lovely apartment. Found a really good beach, park at Littlehampton. Brilliant trip into London on the train. Would recommend as a place to stay." ',
    guest: 'The Rafter Family [Littleborough] May 2011 '
  },

  {
    quote:
      '"Thank you very much. The apartment is fantastic, we all loved it.  Well equipped and everything.  My sister loved the PlayStation :) My family came here to visit me in my exchange year and we had a good time.  We\'ll definitely recommend your apartment, it is beautiful.  We also made day trips to Portsmouth, Southampton, Winchester and and ... :) Great Location!!" ',
    guest: 'The Terstegan family [Germany] April 2011 '
  },

  {
    quote:
      '"Good location and great base to stay for visits to Chichester, Winchester, Southampton and Portsmouth.  Had a good time.  Place very homely with plenty of comforts.  Would certainly recommend as a place to stay." ',
    guest: 'Sue, Annette and Dave [London] January 2011 '
  },

  {
    quote:
      '"Thank you so much for a wonderful stay.  Very peaceful and well equipped.  We have told our friends about it!  May peace, joy and rest be the experience of those who stay here, like it has been ours.  Thanks so much." ',
    guest: 'Peter and Caroline  [Bury St Edmunds] November 2010 '
  },

  {
    quote:
      '"Many thanks Nigel and Jenny for a lovely stay at your apartment.  We have had a very relaxing week.  Just as good as our last visit! Already looking forward to our next !  Many thanks again" ',
    guest: 'The Stafford family [Northampton] November 2010 '
  },

  {
    quote:
      '"Our second visit to this wonderful apartment has been better than ever.  This time we made full use of Zoo bus route to Chichester and Portsmouth (30 mins).  No need to use local restaurants with Waitrose on doorstep.  So lucky with beautiful weather  this week for walks to Langstone.  Emsworth also worth a visit.  Many thanks to Nigel and Jenny for letting us book this lovely holiday home.  Really hope to come again." ',
    guest: 'Chris and June [Suffolk] October 2010 '
  },

  {
    quote:
      '"We had a wonderful week and enjoyed every hour in this wonderful apartment.  Many thanks to Nigel and Jenny for making this time possible." ',
    guest: 'Andreas, Carola and family [Berlin, Germany] October 2010 '
  },

  {
    quote: '"We have had a lovely week.  Thank you so much." ',
    guest: 'Anne and Geoff [Nottingham] September 2010 '
  },

  {
    quote:
      '"Dear Jenny and Nigel,  We booked just for a 2 day stop over but the apartment was so wonderful we wished we had booked for a week.  Definitely  5 star holiday accommodation.  We will be exploring this area in the future and will be re-booking your apartment." ',
    guest: 'Michelle, Ian and family [Spain] September 2010 '
  },

  {
    quote:
      '"This wonderfully large apartment has stabled our whole family.  A wonderful holiday and a big thanks you! I hope the next people will enjoy it as much as us." ',
    guest: 'The Peter family [Germany] August 2010 '
  },

  {
    quote:
      '"Great big thank you!  We have had a most enjoyable week. This apartment was much more than we ever expected. Our daughter enjoyed all the toys.  It was great to be so close to town and the local supermarkets.  We would definitely recommend this apartment to friends and hope to come and stay again sometime.  Many thanks.  " ',
    guest: 'Claire, Mark and family [Bradford] August 2010 '
  },

  {
    quote:
      '"Dear Jenny and Nigel,  We really enjoyed our vacation in your lovely and comfortable apartment.  We had a nice time in England which we do not want to forget.  Thanks for everything." ',
    guest: 'Krapf-Bender family [Germany] July 2010 '
  },

  {
    quote:
      '"Superb accommodation which has been fitted out to the highest standard.  We recommend that anyone with kids pays a visit to West Wittering beach which was well worth the £5 car park fee.  An ideal base to tour the area." ',
    guest: 'Anne, Jon and family [West Lothian] July 2010 '
  },

  {
    quote:
      '"Just to say a big thank you on behalf of all three of us who stayed here over the past week.  A very nicely arranged and finished apartment which suited our needs and which was comfortable to live in.   The neighbours proved to be very friendly and accommodating which made us feel at ease and offered advice on where to go and what to see. Thanks again! " ',
    guest: 'Alun, Linvel  and Julian [London] June 2010 '
  },

  {
    quote:
      '"Dear Jenny and Nigel, Thank you for these wonderful days here - everyone in this book described it in the way would do it - just perfect. " ',
    guest: 'Steffi and Warner [Germany] May 2010 '
  },

  {
    quote:
      '"Thank you for a lovely place to stay.  Your stylish flat had everything we needed and more.  Coming from Canada to visit family we were able to entertain in style!  We also had many enjoyable walks, runs and swims at the Leisure Centre.  I needed vigorous exercise to counterbalance the delicious treats England has to offer.  Thank you!" ',
    guest: 'Bridgett and Fergus [Ontario, Canada] May 2010 '
  },

  {
    quote:
      '"This wonderfully large apartment has enabled our whole family to come to Havant.  We moved to Canada from Waterlooville in 1970.  My sister and I were only 2 and 3.  We came to see my grandparents and other relatives in the Havant area.  This location is perfect.  I enjoyed wondering over to Costa for a gingerbread latte and then to Greggs bakery for a maple pecan swirl.  We have found paths and trails to Emsworth and Langston harbour.  We enjoyed meals in the Royal Oak and the Ship Inn.  It is so nice that the market is right here on Saturdays and Tuesdays." ',
    guest: 'Michelle and Eric [Ontario, Canada] May 2010 '
  },

  {
    quote:
      '"We spent some wonderful days in Havant. The apartment is great.  Our boys started playing with the lego, the minute we arrived.  We enjoyed cooking in this perfect kitchen.  We really enjoyed our trips to Hayling Island and the Sir Hillier Gardens.   We will come back!" ',
    guest: 'Katrin and Jorg [Dresden, Germany] April 2010 '
  },

  {
    quote:
      '"We think everything has already been said about your wonderful apartment. It\'s really lovely.  We enjoyed our stay very much.  We loved your open kitchen and we enjoyed cooking together.  Everything is fine and for us absolutely perfect. You can be sure, we will come back to this fantastic place!    Thanks a lot ! " ',
    guest: 'Schmelter family [Germany]  April 2010 '
  },

  {
    quote:
      '"Thanks for a wonderful "home from home"!  The flat is great - lots of love and thought gone into the planning and interior design.  We loved the big TV and games/films for the kids.  Location is super.  As always, we spent our time at the Historic Dockyard, Fort Nelson and simply walking on the beaches and flying kites.  I\'m sure we�ll be back again very soon. " ',
    guest: 'Teresa, Michael and family [Germany] March 2010 '
  },

  {
    quote:
      '"We had a wonderful stay in your fantastic apartment -  this was the perfect location as it was so easy to get to Portsmouth and Chichester.  So many thoughtful touches made our stay easier -  especially the Robin Hood DVDs for our son William and the great kitchen for the grown ups.  Thanks again! " ',
    guest: 'Robinson family [Baltimore, USA] Jan 2010 '
  },

  {
    quote:
      '"Thank you very much, we had a great week in this gorgeous apartment! The weather was kind and there is so much to do in the area, I think we will have to come again.  The kids enjoyed the toys and PlayStation2 and we enjoyed all the little extras like plenty of crockery, wind glasses etc.  The location is very convenient too.  Thanks for a great week." ',
    guest: 'The McLennan family [Croydon] October 2009 '
  },

  {
    quote:
      '"A wonderful holiday and an English home away from our Australian home.  The apartment is gorgeous, comfortable and the thought that has gone into it is remarkable. We loved Havant even though our pronunciation was way out. Nice and quiet and close to everything.  As a former chef I loved the kitchen and opportunity to use the local produce.  Unforgettable!" ',
    guest: 'Ann, Kris and Olivia [Australia] October 2009 '
  },

  {
    quote:
      '"Last day in our home from home. Couldn\'t have wished for a better place to stay and such a pleasure to come back to after a long day exhibiting.  Would love to stay here again. Looks like you thought of EVERYTHING! DVD\'s too !  Much appreciated." ',
    guest: 'Team Xero [Devon] September 2009 '
  },

  {
    quote:
      '"Warm and sunny for the whole week in this great appartment!  So much to ee, so dont try it all in one week, which is why we\'ll be back.  Bus and trains are good, clean and punctual.  But don\'t do the 20 or 21 bus to portsmouth unless you want to take an hours drive through housing estates.  Bus 200 to Chichester drops you right outside Cathedral and shops.  All in all a beautifully furnished and decorated "home". " ',
    guest: 'Ann and Bob [Northampton] Septermber 2009 '
  },

  {
    quote:
      '"This was our first holiday away as a family with our four month old baby.  We couldn\'t have asked for a more well equipped "home from home".  We don\'t want to leave!! Fantastic flat, stunning, clean and fresh.  Had some wonderful days out, Marwell Zoo is great, Portsmouth lovely and the weather has been very kind.  We\'d most definitely stay here again and recommend to our friends.  Oh and the duvet and pillows are sooo comfy!  Like sleeping in a giant marshmallow!!" ',
    guest: 'The Jones family [Wrexham] August 2009 '
  },

  {
    quote:
      '"Super apartment and wonderful weather.  Kids had a great time and I feel refreshed for "back to work";. Surrounding area is beautiful and it was good to return to such a well appointed base.  " ',
    guest: 'The Hayter family [London] August 2009 '
  },

  {
    quote:
      '"We have a great day on the Isle of Wight - typical English weather it rained. Rest of the week was dry.  Gunwharf Quays to go shopping, Chichester nice and flat.  Highlight of the week was the Spinnaker Tower with great views.  We also had a boat trip around the docks to see frigates etc.  A well equipped and very comfortable base to do all of the above.  We look forward to a return trip." ',
    guest: 'The Stafford family [Northampton] August 2009 '
  },

  {
    quote:
      '"We have had a wonderful time in Havant and the surrounding area.  The apartment has been perfect for exploring as well as being extremely comfortable.  Winchester was a lovely day out; the river and the Cathedral are very beautiful. The New Forest was also lovely. Port Solent (which is just down the road) was excellent for restaurants as well as being extremely quiet.  Thank you for an excellent stay." ',
    guest: 'The Bocking family [Suffolk] July 2009 '
  },

  {
    quote:
      '"Thank you so much.  Lovely apartment and position, great to visit all the places we wanted to. My 80 year old Mother was with us this year on holiday (she lived in Gosport until she married and went to the midlands) so she took us on some memory lane trips.  Spinnaker Tower the highlight of the week, what fantastic views. Will be back, thanks again." ',
    guest: 'Julie and Mark [Wolverhampton] July 2009 '
  },

  {
    quote:
      '"What a wonderful, comfortable and well equipped apartment.  As previous guests have said, it\'s nice to come back to after a day out.  Brilliant situation near the shops. Chichester and the Witterings are well worth a visit and the New Forest was not too far.  Staunton Country Park a must especially with children! (only 2 miles away).  Lovely walks around Chichester harbour.  Thank you for a wonderful week.  Hope to pay another visit in the future." ',
    guest: 'June and John [Ipswich] June 2009 '
  },

  {
    quote:
      '"This was a very convenient place to stay and we loved the light and airy main room, it was great to have a washing machine/dryer, ironing board and a very well equipped kitchen.  Gunwharf Quays and the Naval Historic dockyards are a must, it took us 3 days to see it all and the best find was the antique shop - we all made purchases there !  Walked to the Royal Oak at Langstone and enjoyed the atmosphere.  Would definitely recommend this !" ',
    guest: 'The Standley family [California, USA] June 2009 '
  },

  {
    quote:
      '"Very nice accommodation, excellently furnished and equipped.  We have had a great time visiting Spinnaker tower, Southsea and Goodwood races.  The boys especially enjoyed the Action Stations museum in the Historic Dockyard.  Thank you for a great holiday. P.S. Highly recommend The Ship at Langstone and the Old House at Home in town." ',
    guest: 'The Walker family [Leighton Buzzard] May 2009 '
  },

  {
    quote:
      '"Thank you very much!  We had a very nice stay in your beautiful apartment, very well equipped and comfortable! Very practical, near shops and trains. We went to London and Portsmouth and the weather was so fine." ',
    guest: 'Christine, Christophe and family [France] April 2009 '
  },

  {
    quote:
      '"Another great stay at Vellum Court.  No snow this year, instead the week started with blazing sunshine.  What we love about your apartment is that it\'s such a great place to come back to in the evening.  The PlayStation is particularly popular this time.  Many thanks." ',
    guest: 'Crees Family [Essex] April 2009 '
  },

  {
    quote:
      '"Great accommodation in a good location.  Very warm and comfortable, lovely place to work.  Thank you." ',
    guest: 'Karen and Wendy [Aylesbury] January 2009 '
  },

  {
    quote:
      '"Fabulous place to stay !  Location excellent; shops, station, motorway (and yet quiet at night).  Apartment beautifully appointed and so well equipped that we were able to cook our Christmas lunch! (Vegetables bought in market).  Happy New Year ! " ',
    guest: 'The McMillan Family [Voorschoten, The Netherlands] December 2008 '
  },

  {
    quote:
      '"We very much enjoyed our stay at your lovely apartment and we are sorry our stay was a short one and hope to come again sometime." ',
    guest: 'The Jenkins family [Cardiff] December 2008 '
  },

  {
    quote:
      '"The apartment is very comfortable and well positioned to explore from.  We particularly liked that we were so close to the shops, coffee etc.   Explored the new forest, we especially liked the Royal Oak outside Lyndhurst. The QE2 was docked at Southampton, it was great to say goodbye to her.  Carisbrooked Castle on the isle of Wight near new port is a must see.  We also did a day trip to Winchester and Salisbury very much worth the travel.  Thank you for sharing your wonderful apartment and world." ',
    guest: 'Hilton, Michelle and Kayla [South Africa] November 2008 '
  },

  {
    quote:
      '"Had a great time!  Would recommenced the blue reef aquarium for children in Portsmouth then walk around Gun wharf Quays.   Went to a great fireworks display in Portsmouth.  Thanks for a great stay in your wonderful apartment! " ',
    guest: 'The Shearman family [Gloucestershire] November 2008 '
  },

  {
    quote:
      '"Thank you for a lovely base for our family holiday in Hampshire.  Your apartment was excellent and catered for our every need.  The children were delighted with the games and Playstation provided and the kitchen was very well equipped. We had a great day out at the Historic Dockyard which I would strongly recommend and shopping at Gunwharf Quays is a must.  Thanks again." ',
    guest: 'Scott and Helen [Warwickshire] November 2008 '
  },

  {
    quote:
      '"Have had a great stay in your excellent apartment whilst visiting family in Havant, it\'s been a home from home.  Marion and Bruce, N.E. England and Ontario, Canada" ',
    guest: 'Marion and Bruce [N.E. England and Ontario, Canada] October 2008 '
  },

  {
    quote:
      '"Here for the Goodwood revival meeting.  Ample room for the three of us in a lovely modern conversion.  Weather was beautiful. Didn\'t get to look around Havant or the surrounding area - racing was more important!! Would recommend Vellum Court to the family." ',
    guest: 'Dave, Paul and Sandeep [Leeds] September 2008 '
  },

  {
    quote:
      '"This is a lovely apartment to stay in and is well equipped, and would be somewhere we would stay in the future. Even though it was thundery and rainy this week there was plenty to do. For shopping I would recommend Chichester and Portsmouth. For walks I recommend Staunton Park as it is a lovely walk and can do anything from one mile to three miles. For the one hot day we had we went to West Wittering Beach, this is a lovely sandy beach which you can spend all day on. The apartment is in a good location and we had a great time. PS Emsworth is definitely worth a visit." ',
    guest: 'The Sexton family [London] August 2008 '
  },

  {
    quote:
      '"We have returned with my parents with us.  The weather was fab - very hot! We loved Goodwood House, Arundel Castle.  Baliffs Court Hotel for afternoon tea, then a walk to the beach and shop in their Spa.  Portsmouth harbour and Shops, Gary and Dad went up the Spinaker Tower. Loved the boat tour.  Caught the ferry over to the Isle of Wight on a beautiful morning, go early.  Visited Orsbourne House and Cowes for Fish and Chips.  Chichester for shopping and out tonight to the Royal Oak in Langstone - 5 stars. The apartment is a wonderful base.  We all had a great time.  Thank you." ',
    guest: 'Carole and Gary [Mid Glamorgan] July 2008 '
  },

  {
    quote:
      '"We\'ve had a lovely stay here at Vellum Court.  The apartment was fabulous and the kitchen was fully equipped! Thank you for all the baby extras - they were a great help.  We had a great week visiting local towns and attractions including Staunton farm, Reef Aquarium, Marwell Zoo - our little boy loved it there.  As well as Portsmouth and Chichester, West Wittering beach is a must!! Thanks again for everything - it was a very enjoyable break. PS Catching a hovercraft over to the Isle of Wight is a must for the children!" ',
    guest: 'Ramsden family [Staffordshire] June 2008 '
  },

  {
    quote:
      '"We had a wonderful time!  The apartment was perfect!  Thank you so much for having the portacrib and the highchair all ready for us.  We went into Chichester, as a lot of your other guests had suggested, and took the kids to West Wittering beach so they could stick their feet in the ocean. They loved it. We also met some friends at 36 Quay per your recommendation.  We loved it." ',
    guest: 'Hensley family [Albuquerque,USA] June 2008 '
  },

  {
    quote:
      '"Thank you for a lovely stay in a beautiful apartment.  It has everything we needed, we certainly know where to come when we visit family next time. The children thought it was fantastic and want to stay here to live. Many thanks." ',
    guest: 'The Venner family [Germany] April 2008 '
  },

  {
    quote:
      '"We had a lovely holiday, including snow on the first morning! In fact we enjoyed it so much that we\'re thinking about booking again for next Easter." ',
    guest: 'Liz and Peter [Essex] April 2008 '
  },

  {
    quote:
      '"Wow, what a great stay in a wonderful apartment.  We enjoyed the visit to Havant and our stay at Vellum Court.  We will return! PS We really appreciate the baby "extras" you left for us - they were extremely useful and thoughtful too.  The holiday decorations made our Christmas very festive." ',
    guest: 'Keith, Barbara and Millie [Vancover BC, Canada] December 2007 '
  },

  {
    quote:
      '"An excellent stay in a very comfortable apartment, in a very central location.  We hope to return in the not too distant future (The Spinnaker Tower is definitely worth a visit!) " ',
    guest: 'Doug and Dorris [London] November 2007 '
  },

  {
    quote:
      "\"Thanks for a home from home and so near my family in Portsmouth and Darren's in Southampton.  I know the area so well (my grandparents live in Warblington and I did my first teaching practice nearly 20 years ago just down the road, literally), it's been great. We watched an awesome sunset on Hayling beach on Wednesday and the  kids loved Fisher's Farm Park near Illinghurst (what a beautiful drive and a fantastic day). The Tuesday and Saturday market was good, especially the olives stall and Gunwharf Quays supplied us with School shoes and all our gear for our trip to Iceland in February.  We will certainly recommend your accommodation.  Thanks for all the extras and thoughtful touches.\" ",
    guest: 'The Shaw family [Newquay] October 2007 '
  },

  {
    quote:
      '"We have had a lovely holiday.   The apartment is very comfortable.  The children really enjoyed the Historic Dockyard, Portsmouth (2 days spent there !) and the Spinnaker Tower." ',
    guest: 'The Schorfield family [Worcestershire] October 2007 '
  },

  {
    quote:
      "\"This is a perfect spot for visiting Hampshire and West Sussex.   The apartment is beautiful. It's worth catching the train from Havant to Portsmouth Quay, £3.40 return which is great value.  The views from Spinaker Tower are fab and the tour of the historic dockyard is very good. Also worth a visit is Arundel Castle, Brighton, Goodwood House tour, Goodwood races, Chichester, Petworth House (with it's NT Art Collection and deer park), West Wittering beach, Emsworth, walk along Mill Pond and along past the Yacht Club to look  at the fab houses and Stansted Park for the food. We have been very lucky with the weather, pity we have to leave.  Looking forward to staying another time. Thank you Jenny and Nigel, can't wait to come back.\" ",
    guest: 'Carole and Gary [Mid Glamorgan] September 2007 '
  },

  {
    quote:
      '"Lovely apartment, well appreciated.  As a central place for days out this has taken some beating. We shall return! " ',
    guest: 'John and Theresa [Orkney] September 2007 '
  },

  {
    quote:
      '"A lovely apartment ideally situated for going East West or up to London. We were lucky enough to get tickets to see Patrick Stewart a the Chichester Festival as well as the England/India game a the Rose Bowl. To top it off Durham beat Hampshire at Lords!  What a fortnight !!" ',
    guest: 'David, Lorraine and Like [Co. Durham] August 2007 '
  },

  {
    quote:
      "\"Excellent accommodation and very good location for shops and railway links.  A trop from Havant to Shanklin on the Isle of Wight represented good value as did the '4 for 2' ticket offer to Portsmouth. The Salterns Way cycle track was a good day out and for an evening meal try Nicolino's Italian restaurant at Emsworth.\" ",
    guest: 'Crogers and Langtons [Colchester] August 2007 '
  },

  {
    quote:
      '"Beautiful apartment, easy to find.  Could reach local cities of Portsmouth and Southampton quite easily.  Will be back! Thank you." ',
    guest: 'Malcolm, Jill, Laura and Elliot [Co Durham] July 2007 '
  },

  {
    quote:
      '"Thank you for a wonderful stay in your apartment.  The location was spot on .  Ideal for getting about.  Local transport system excellent, no need to use the car. Local area was very interesting and did not have enough time in a week to do everything. Would certainly come again, we will recommend to our family and friends." ',
    guest: 'The Oswalds and Sloans [Co. Durham] July 2007 '
  },

  {
    quote:
      '"Many thanks Nigel and Jenny for the use of your beautiful apartment, this is cosy and quiet yet handy for everywhere.  We enjoyed mostly sunny weather and enjoyed walking along the shore from Emsworth to Langstone and also Meon shore.  We also had a home cooked meal with ingredients from the lovely Saturday market.  We will be back!"',
    guest: 'John and Carole [Isle of Man] July 2007 '
  },

  {
    quote:
      '"Thanks for letting us use your flat, it\'s lovely.  We had a great week although the weather sometimes let us down.  Visited lots of local places, my favourite being the Isle of Wight. Would certainly recommend your flat to friends and relatives. Thanks again." ',
    guest: 'Lindsey and Pete [Nottinghamshire] June 2007 '
  },

  {
    quote:
      '"We have thoroughly enjoyed our week here.  The flat is lovely and well fitted out with everything we need, very comfortable.  With glorious weather, we have visited many places. Chichester is lovely, we listened to an Organ recital in the Cathedral and had lunch in the "Cloisters".  We then returned in the evening to a see a show at the Festival Theatre. We can recommend Bosham and the Millstream Hotel for a superb meal. Uppark House (NT) is set in beautiful grounds and well worth a visit. Looking forward to visiting and staying here again." ',
    guest: 'Pam and Laurie [Maidenhead] June 2007 '
  },

  {
    quote:
      '"We had a great weekend, the wedding was wonderful, shame about weather but the apartment was a good place to hide out from it. Many thanks" ',
    guest: 'McKenzie family [Staines] May 2007 '
  },

  {
    quote:
      '"What a shame we have to leave.  Thank you so much for the flat and we will be back on the 8th - 29th June when hopefully we will stop living out of our suitcase and complete on our house.  Vellum Court  has been a Godsend to us, you really do not know how grateful we are !!  P.S. We will always recommend this place to our friends and relatives in the future. It\'s beautiful." ',
    guest: 'Sam, Spencer and Kenya [Havant] May 2007 '
  },
  {
    quote:
      "\"Thank you for providing such a delightful place to stay. We were out and about a lot as the weather was so wonderful, but even if we weren't it's a great place to chill.  The kitchen was great - very well equipped with lots of worktop space. In terms of things to do, our children were out sailing most days at an event at Hayling Island Sailing Club and we cycled.   The cycle path to Langstone and onwards to the Hayling Billy trail was lovely.  Part of the Haying Billy trail was officially closed for maintenance but many people were using it anyway. We also drove to Chichester and cycled from there down Saltern's Way to Itchenor and  to West Wittering - again lovely.  Lunch at the Ship in Itchenor was very good but pricey - you could eat cheaply if you chose carefully and you could push the boat out too. Looks like it would get busy at weekends. We also cycled west to visit friends at Cosham, but it's not such a pretty route and they are clearly still joining the gaps in the cycle paths. It was great to be close to the shops in Havant for buying something to eat most days and we didn't have to 'do' Tesco's, so it was definitely a proper holiday. Many thanks.\" ",
    guest: 'Groves Family [London] April 2007'
  },

  {
    quote: '" Many thanks for a lovely stay at your beautiful flat." ',
    guest: 'Iain, Chrys and Andy [London] March 2007'
  }
];
